import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PopupBox from "./PopupBox";
import PopupSelectField from "./ui-el/PopupSelectField";
import { onSelectData2, serviceFinder, titleExtractor, titleNormalizer, uniqueIdBuilder } from "../util/Dynamics";
import PopupTextField from "./ui-el/PopupTextField";


function PreferenceRegistryPopup(props) {
    const preferencesCtx = useSelector(state => state.stepSlice.data.preferenze);

    const [_, setSelection] = useState("");

    const [data, setData] = useState(props?.registryData.preferenze ?? preferencesCtx);

    let serviceName = serviceFinder(props?.service);

    const classChange = (e) => {
        setData(prev => {
            const newObj = { ...prev };

            const internal = { ...newObj[serviceName] };
            internal.classe = e.target.value;

            newObj[serviceName] = internal;
            return newObj;
        })
    }

    const edit = () => {
        props.update({
            id: "Preferenze",
            data: data
        });

        props.close();
    }

    const onChange = (e, key, title, isLinear = false) => {
        setData(prev => {
            const obj = structuredClone(prev);
            if (!isLinear) {
                obj[serviceName][key][title] = e.target.value;
            }
            else {
                obj[serviceName][title] = e.target.value;
            }

            return { ...prev, ...obj }
        })
    }

    const [shouldClearIndirizzo, setShouldClearIndirizzo] = useState(false);

    useEffect(() => {
        if (shouldClearIndirizzo) {
            setData(prevData => ({
                ...prevData,
                [serviceName]: {
                    ...prevData[serviceName],
                    indirizzo_spedizione: ""
                }
            }));
            setShouldClearIndirizzo(false);
        }
    }, [shouldClearIndirizzo, serviceName]);

    // console.log("///////////////////// preference popup", data, serviceName);

    const render = () => {
        return <PopupBox
            close={props.close}
            save={edit}
            title="Preferenze">
            {
                Object.keys(data[serviceName]).map((key, idx) => {

                    // Verifica se la modalità di ritiro è "Invio tramite raccomandata"
                    const isModalitaRaccomandata = data[serviceName]?.modalita === "Invio tramite raccomandata";

                    // Verifica se l'invalidità è "Permanente"
                    const isPermanente = Array.isArray(data[serviceName]?.tipo_di_invalidita) ? data[serviceName]?.tipo_di_invalidita?.[0]?.toLowerCase() === "permanente" : data[serviceName]?.tipo_di_invalidita?.toLowerCase() === "permanente";

                    // Se l'invalidità è permanente, non mostrare i campi relativi a "Marca da bollo"
                    if (isPermanente && key.includes("marca")) {
                        return null;
                    }

                    // Verifica per escludere "Indirizzo di spedizione" se la modalità non è "Invio tramite raccomandata"
                    if (key === "indirizzo_spedizione" && !isModalitaRaccomandata) {
                        if (!shouldClearIndirizzo) {
                            setShouldClearIndirizzo(true);
                        }
                        return null; // Non mostrare "Indirizzo di spedizione"
                    }

                    const options = props.choiceData.filter(itm => {
                        if (key.includes(titleNormalizer(itm.title[1]).normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
                            return true;
                        else
                            return false;
                    });


                    // console.log("isisPermanente", isPermanente, key, data[serviceName][key])



                    if (key === "classe" && data[serviceName][key] !== "" && (props.service === "RMS" || props.service === "RTS")) {
                        return <PopupTextField
                            defaultValue={data[serviceName][key] ? data[serviceName][key] : preferencesCtx[key]}
                            onBlur={classChange}
                            id={key}
                            fieldTitle={key}
                            key={uniqueIdBuilder()} />
                    }

                    if (options.length > 0) {
                        let values = [];

                        for (let i of options) {
                            values = i.options.map(itm => itm.value);


                            if (i.type === "MultiText") {
                                return i.options.map(el => {
                                    const normalizedTitle = titleNormalizer(el.title).normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                                    //child title gotta be 0 if you want the property treated like a first level
                                    if (i?.child_title) {
                                        if (i.child_title.length === 0) {
                                            const linears = ["indirizzo_spedizione", "motivo", "vecchio_contrassegno", "m_data", "numero_1", "data_marca_1", "numero_2", "data_marca_2"];
                                            // console.log("here", key);

                                            if (i.title[1] === "M DATA") {
                                                return <PopupTextField
                                                    fieldType="date"
                                                    defaultValue={data[serviceName][normalizedTitle]}
                                                    onChange={(e) => { onChange(e, key, normalizedTitle, linears.includes(normalizedTitle)) }}
                                                    id={titleNormalizer(normalizedTitle)}
                                                    fieldTitle={"Data del matrimonio (presumibile)"}
                                                    key={normalizedTitle} />
                                            }


                                            // //test marca da bollo
                                            if (key.includes("numero")) {
                                                return <PopupTextField
                                                    defaultValue={data[serviceName][normalizedTitle]}
                                                    onChange={(e) => { onChange(e, key, normalizedTitle, linears.includes(normalizedTitle)) }}
                                                    id={titleNormalizer(normalizedTitle)}
                                                    fieldTitle={el.title}
                                                    key={normalizedTitle}
                                                    max="14"
                                                    regex="^[0-9]{14}$"
                                                />
                                            } else if (key.includes("data")) {
                                                return <PopupTextField
                                                    fieldType="date"
                                                    defaultValue={data[serviceName][normalizedTitle]}
                                                    onChange={(e) => { onChange(e, key, normalizedTitle, linears.includes(normalizedTitle)) }}
                                                    id={titleNormalizer(normalizedTitle)}
                                                    fieldTitle={el.title}
                                                    key={normalizedTitle}
                                                    max={new Date().toISOString().split('T')[0]}
                                                />
                                            }



                                            return <PopupTextField
                                                defaultValue={data[serviceName][normalizedTitle]}
                                                onChange={(e) => { onChange(e, key, normalizedTitle, linears.includes(normalizedTitle)) }}
                                                id={titleNormalizer(normalizedTitle)}
                                                fieldTitle={el.title}
                                                key={normalizedTitle} />
                                        }
                                    }

                                    //test marca da bollo
                                    if (!isPermanente && key.includes("marca")) {
                                        if (el.title.toLowerCase() === "numero") {
                                            return <PopupTextField
                                                defaultValue={data[serviceName][key][normalizedTitle]}
                                                onChange={(e) => { onChange(e, key, normalizedTitle) }}
                                                id={titleNormalizer(normalizedTitle)}
                                                fieldTitle={el.title}
                                                key={normalizedTitle}
                                                max="14"
                                                regex="^[0-9]{14}$"
                                            />
                                        } else if (el.title.toLowerCase() === "data") {
                                            return <PopupTextField
                                                fieldType="date"
                                                defaultValue={data[serviceName][key][normalizedTitle]}
                                                onChange={(e) => { onChange(e, key, normalizedTitle) }}
                                                id={titleNormalizer(normalizedTitle)}
                                                fieldTitle={el.title}
                                                key={normalizedTitle}
                                                max={new Date().toISOString().split('T')[0]}
                                            />
                                        }
                                    }
                                    //fine test marca da bollo

                                    return <PopupTextField
                                        defaultValue={data[serviceName][key][normalizedTitle]}
                                        onChange={(e) => { onChange(e, key, normalizedTitle) }}
                                        id={titleNormalizer(normalizedTitle)}
                                        fieldTitle={el.title}
                                        key={normalizedTitle} />
                                })
                            }
                        }

                        if (!Array.isArray(data[serviceName][key])) {
                            return <PopupSelectField
                                defaultValue={data[serviceName][key] ? data[serviceName][key] : preferencesCtx[serviceName][key]}
                                onChange={(e) => { onSelectData2(e, serviceName, setData, setSelection) }}
                                id={key}
                                fieldTitle={titleExtractor(key)}
                                options={values}
                                description="Seleziona la preferenza"
                                key={uniqueIdBuilder()} />

                        } else {
                            if (props.service === "PPI") {

                                return <PopupSelectField
                                    defaultValue={data?.[serviceName]?.[key]?.[0] || (preferencesCtx?.[serviceName]?.[key] ? preferencesCtx?.[serviceName]?.[key]?.[0] : "")}
                                    onChange={(e) => { onSelectData2(e, serviceName, setData, setSelection) }}
                                    id={key}
                                    fieldTitle={titleExtractor(key)}
                                    options={values}
                                    description="Seleziona la preferenza"
                                    key={uniqueIdBuilder()} />
                            }
                        }
                    }
                })
            }
        </PopupBox>
    }

    return (
        <>
            {render()}
        </>
    )
}

export default PreferenceRegistryPopup;